import { Dict, GenericObject } from '../core/util/generics';
import { RootState as NewRootState } from './../store/configureStore';
import { AccountRole, BookingStatus, EligibilityStatuses, QueueTab } from '../constants';
import { LabelValue } from '../core/form/common';
import { LANG_MAP } from '../constants/lang';
import {
  NotificationType,
  Variant,
} from '../components/Settings/NewSettings/PatientCommunications/types';
import { Snakify } from '../core/util/snakify';

export type RootState = NewRootState;

export type ManualRootState = {
  accounts: Accounts;
  GenericObject: GenericObject;
  autocomplete: GenericObject;
  bookingHistory: BookingHistory;
  bulkClinicAccountActivation: BulkClinicAccountActivation;
  bulkUpload: BulkUpload;
  categories: any[];
  charges: GenericObject;
  clinicAccountActivation: GenericObject;
  clinicAccounts: GenericObject;
  clinicDownloadReports: ClinicDownloadReports;
  contacts: GenericObject;
  conversations: StoreConversations;
  currentPatient: CurrentPatient;
  directory: GenericObject;
  eligibilityCheck: EligibilityCheck;
  graphs: GenericObject;
  groups: GenericObject;
  handoffs: GenericObject;
  imageData: ImageData;
  imageUpload: GenericObject;
  insurance: GenericObject;
  insurers: any[];
  labResults: LabResults;
  language: 'en' | 'es' | 'so' | 'hmn';
  location: GenericObject;
  locationConsentForms: LocationConsentForms;
  locationsImportToolFieldOptions: any[];
  login: AccountLogin;
  lookUp: LookUp;
  marketplaceInsurers: any[];
  modalLocationIds: ModalLocationIDS;
  newBooking: GenericObject;
  packages: any[];
  paperwork: {
    customFields: PaperworkField[];
    customKioskFields: PaperworkField[];
    error?: string;
    facesheetFields: PaperworkField[];
    standardFacesheetFields: PaperworkField[];
    standardFields: PaperworkField[];
    standardKioskFields: PaperworkField[];
  };
  paperworkResponses: PaperworkResponses;
  pastBookings: PastBookings;
  paymentCustomers: GenericObject;
  payments: Payments;
  platformUsageAlerts: GenericObject;
  premiumVisitWrapUpModal: Modal;
  providerLocations: {
    results: any[];
  };
  providers: GenericObject;
  pulse: GenericObject;
  queue: Queue;
  report: GenericObject;
  reports: Reports;
  reviews: Reviews;
  reviewsModeration: ReviewsModeration;
  runtime: Runtime;
  serviceLocations: GenericObject;
  servicePreferences: {
    data: any[];
  };
  settings: Settings;
  sftpExportConfig: SftpExportConfigState;
  solvPatientBoost: SolvPatientBoost;
  specialties: any[];
  brands: Brand[];
  uclBrands: any[];
  vaccinations: GenericObject;
  videoVisitModal: Modal;
  waitList: WaitList;
  waitTime: WaitTime;
  _persist: Persist;
};

export interface Persist {
  version: number;
  rehydrated: boolean;
}

export interface Accounts {
  accounts: any[];
  submitting: boolean;
  updateAccountSuccess: GenericObject;
  updateAccountError: GenericObject;
}

export interface BookingHistory {
  bookingHistory: any[];
}

type IntegrationAction = Booking['integration_status'][0]['action'];

export interface Booking {
  old_booking_id?: string;
  number: string;
  insurance: {
    insurerType: string;
  };
  isPriority: boolean;
  checkInSource: string;
  preferredTime: string;
  isExistingPatient: boolean;
  reasonForVisit: string;
  account_id: string;
  active: boolean;
  appointment_date: string;
  appointmentDate: number;
  appointment_date_at_clinic_tz: string;
  are_required_paperwork_fields_answered: boolean;
  arrive_to_ready_minutes: number | string;
  arrived_timestamp: string;
  arrived_timestamp_at_clinic_tz: string;
  attribution: string;
  author_id: string;
  author_type: string;
  birth_date: string;
  calendar_date: string;
  cancellation_reason: string;
  check_in_source: string;
  checked_in_timestamp: string;
  consent_forms_signed_date: string;
  consent_forms_status: string;
  created_date: string;
  dental_insurance_profile_id: string;
  discharged_timestamp: string;
  door_to_door_minutes: null;
  eligibility_status: string;
  email: string;
  exam_to_discharge_minutes: null;
  external_appointment_reason: string;
  first_name: string;
  has_associated_lab_results: boolean;
  has_been_rescheduled: boolean;
  has_insurance_info: boolean;
  has_insurance_problem: boolean;
  has_uploaded_photo_id_back: boolean;
  has_uploaded_photo_id_front: boolean;
  id: string;
  in_exam_room_timestamp: string;
  insurance_coverage_result_id: string;
  insurance_profile_id: string;
  insurer_type: string;
  integration_status: Array<{
    action:
      | 'add-patient'
      | 'schedule-appointment'
      | 'update-demographics'
      | 'upload-insurance-card'
      | 'upload-photo-id'
      | 'upload-consent-form'
      | 'upload-paperwork-pdf'
      | 'cancel-appointment';
    service_transaction_log_id: string;
    service_identifier: string;
    video_path?: string;
    requests?: Array<{
      action?: IntegrationAction;
      fields?: string[];
      success?: boolean;
      patientId?: number;
      searching_algo?: string;
      videoLogPrefix?: string;
      patient_match_status?: string;
      isBookingScheduled?: boolean;
      status?: 'none' | 'in-progress' | 'success' | 'paused' | 'warning' | 'error' | 'dismissed';
      request?: any;
      message?: string;
    }>;
    integration_start: string;
    resolution?: 'dismissed';
    status: 'none' | 'in-progress' | 'success' | 'paused' | 'warning' | 'error' | 'dismissed';
    message?: string;
    error?: string;
    integration_duration?: string | undefined;
    profile_matches?: string | null;
    emr_id?: string | undefined;
  }>;
  patient_match_details: {
    external_user_profile_id?: string;
    manual_patient_match_required: boolean;
    patient_match_status?:
      | 'newly-created-patient'
      | 'matched-existing-patient'
      | 'failed-multimatch-found'
      | 'failed-match-by-demographics'
      | 'failed-patient-match-by-id'
      | 'failed-patient-match-by-id-birthdate-mismatch'
      | 'failed-create-new-patient'
      | 'failed-create-new-patient-multimatch';
  };
  emr_id: string;
  invoice_amount: number;
  invoice_id: string;
  invoice_status: string;
  is_address_validated: boolean;
  is_call_ahead: boolean;
  is_covered_by_membership: boolean;
  is_direct_telemed: boolean;
  is_existing_patient: boolean;
  is_external_telemed: boolean;
  is_last_payment_added_after_booking: boolean;
  is_last_payment_added_less_than_week_before_booking: boolean;
  is_logged_in: boolean;
  is_paperwork_bypassed: boolean;
  is_payment_bypassed: boolean;
  is_persistent_login: boolean;
  is_premium_visit: boolean;
  is_priority: boolean;
  is_telemed_patient_in_call: boolean;
  is_walkin: boolean;
  language: string;
  last_name: string;
  last_name_telemed_provider_in_call: string;
  lat_long: string;
  links: Link[];
  location_display_name_primary: string;
  location_display_name_secondary: string;
  location_id: string;
  location_name: string;
  notes: null | string;
  origin: string;
  original_appointment_date: string;
  paperwork_completed: boolean;
  paperwork_response_types: any[];
  paperwork_status: string;
  partner_type: string;
  phone: string;
  preferred_time: string;
  premium_office_visit_tier: number;
  provider_id: null;
  provider_in_call_clinic_account_ids: any[];
  provider_name: string;
  rating: number;
  rating_date: null;
  ready_to_exam_minutes: null;
  reason: string;
  rescheduled_to: null;
  review: null;
  sent_follow_up_text_timestamp: null;
  sms_consent: boolean;
  status: BookingStatus;
  telemed_status: string;
  time_zone: string;
  tokbox_session_id: string;
  transferred_from_display_name_primary: null;
  transferred_from_display_name_secondary: null;
  transferred_to_display_name_primary: null;
  transferred_to_display_name_secondary: null;
  triaged_timestamp: string | null;
  uber_ride_status: string;
  updated_date: string;
  updated_source: string;
  user_profile_id: string;
  vision_insurance_profile_id: string;
  wait_time_minutes: number;
  zip_code: string;
  zip_lat_long: string;
  booking_family_member_type: 'primary' | 'auxiliary';
  booking_family_id: string;
  has_uploaded_insurance_card_front?: boolean;
  has_uploaded_insurance_card_back?: boolean;
  authorized_payment_method_stripe_id?: string;
  eligibility_info?: {
    eligibility_request_eligibility_status: EligibilityStatuses | null;
    eligibility_request_updated_date: string;
  };
  is_telemed_booking: boolean;
  visit_type_id: string;
}

export interface Link {
  href: string;
  rel: string;
}

export interface QueueTabSummary {
  average_door_to_door_today_in_minutes: number;
  average_door_to_door_yesterday_in_minutes: number;
  average_wait_time_in_minutes: number;
  average_wait_time_yesterday_in_minutes: number;
  avg: number;
  created_date: string;
  id: string;
  location_id: string;
  patients_last_week: number;
  patients_this_week: number;
  patients_today: number;
  patients_yesterday: number;
  percent_after_hours_this_week: number;
  percent_after_hours_two_weeks_ago: number;
  percent_online_this_week: number;
  percent_online_two_weeks_ago: number;
  percent_seen_on_time_this_week: number;
  percent_seen_on_time_two_weeks_ago: number;
  ranking_text: string;
  updated_date: string;
}

export interface QueueTabBookings {
  currentPage: number;
  hasMorePages: boolean;
  loading: boolean;
  bookings: Dict<Booking>;
  error?: string;
  summary?: QueueTabSummary;
  todayCount: number;
}

export interface BulkClinicAccountActivation {
  successes: any[];
  errors: any[];
  isCreatingBulkAccounts: boolean;
}

export interface BulkUpload {
  bulkBills: BulkBills;
}

export interface BulkBills {
  rowError: any[];
  softMatch: any[];
  hasInvoice: any[];
  invoiceCreated: any[];
}

export interface ClinicDownloadReports {
  data: null;
  createDownloadReportStatus: string;
  createDownloadReportErrorText: string;
}

export interface StoreConversations {
  currentConversation: Conversation;
  conversations: ConversationsConversations;
  messagesByConversationId: GenericObject;
  unreadCount: number;
  chatModalVisible?: boolean;
  modalOpenedFrom?: string;
  cannedMessagesModalVisible?: boolean;
  isFetchingConversations?: boolean;
  sending?: boolean;
  error?: string;
  sendError?: string;
}

export interface ConversationsConversations {
  conversations: Dict<Conversation>;
  currentPage: number | null;
  hasMorePages: boolean | null;
  unreadCount?: number;
}

export interface InsuranceProfile {
  id: string;
  account_id: string;
  first_name: string;
  birth_date: string;
  middle_name: string;
  last_name: string;
  member_code: string;
  insurer_id: string;
  insurer_type: string;
  insurance_type: string;
  active: boolean;
  insurer_name: string;
  card_front_id: string;
  card_back_id: string;
  eligibility_result: string;
  has_uploaded_id: boolean;
}

export interface TraditionalLocationConsentForm {
  locationConsentFormId: string;
  idHash: null | string;
  displayName: string;
  locationId: string;
  groupId: string;
  awsBucketName: string;
  awsPath: string;
  active: boolean;
  createdDate: string;
  updatedDate: string;
  formType: 'traditional';
  dynamicTemplateFilename: null;
}

export interface DynamicLocationConsentForm {
  locationConsentFormId: string;
  idHash: null | string;
  displayName: string;
  locationId: string;
  groupId: string;
  awsBucketName: null;
  awsPath: null;
  active: boolean;
  createdDate: string;
  updatedDate: string;
  formType: 'dynamic';
  dynamicTemplateFilename: string;
}

export interface ConsentFormSignature {
  id: string;
  signedDate: string;
  signerIpAddress: string;
  documentHashingFunction: string;
  documentSignature: string;
  publicKey: string;
  locationConsentFormId: string;
  signerAuthenticationMethod: string;
  bookingId: string;
  accountId: string;
  locationId: string;
  groupId: string;
  createdDate: string;
  updatedDate: string;
  locationConsentForm: DynamicLocationConsentForm | TraditionalLocationConsentForm;
}

export interface CurrentPatient {
  summary: Patient;
  booking?: Booking;
  consentForms?: Snakify<ConsentFormSignature>[] | { error?: string };
  insuranceProfile?: any;
  userProfile: UserProfile;
  eligibility?: { id: string };
}

export interface Patient {
  active: boolean;
  address: string;
  address_street_secondary: string;
  city: string;
  created_date: string;
  email: string;
  first_login: null;
  first_name: string;
  id: string;
  is_active_membership: boolean;
  is_address_validated: boolean;
  is_android_app_user: boolean;
  is_ios_app_user: boolean;
  is_iterable_identified: boolean;
  is_mixpanel_aliased: boolean;
  is_valid_phone: boolean;
  is_verified_phone: boolean;
  last_name: string;
  last_tos_acceptance_date: Date;
  links: Link[];
  login_count: number;
  market: string;
  membership_account_id: string;
  onboarding_date: string;
  origin: string;
  phone: string;
  requires_tos_consent_initial: boolean;
  requires_tos_consent_update: boolean;
  risk_partner_id: string;
  sms_consent: boolean;
  solv_employee: boolean;
  solv_user_date: Date;
  state: string;
  stripe_customer_id: string;
  tos_consent: string;
  type: string;
  updated_date: string;
  zip_code: string;
}

export interface EligibilityCheck {
  eligibilityFeedbackFailure: boolean;
  eligibilityFeedbackSubmitting: boolean;
  eligibilityFeedbackSuccess: boolean;
  error: null;
  faceSheet: GenericObject;
  result: { results: GenericObject[] };
  submitting: boolean;
}

export interface ImageData {
  frontImageUrl: null;
  backImageUrl: null;
  data: null;
}

export interface LabResults {
  imports: Imports;
  results: Imports;
  resultFilters: GenericObject;
  importFilters: GenericObject;
  labResultUploads: GenericObject;
}

export interface Imports {
  page: GenericObject;
  results: any[];
}

export interface LocationConsentForms {
  isUpdating: any[];
}

export interface Locations {
  results: Dict<Location & LocationUnformatted>;
  loading: boolean;
  error: null;
}

export interface LatLong {
  latitude: string;
  longitude: string;
}

export enum PlatformType {
  ProviderGroup = 'provider_group',
  UrgentCare = 'urgent_care',
}

export interface Specialty {
  display_name: string;
  name: PlatformType;
  specialty_field: PlatformType;
  specialty_id: string;
}

export interface SpecialtyV2 {
  displayName: string;
  name: PlatformType;
  specialtyField: PlatformType;
  specialtyId: string;
}

export interface ServiceLocation {
  active: boolean;
  external_location_id: string | null;
  id: string;
  is_test_mode: boolean | null;
  location_id: string;
  provider_identifier: string | null;
  service_identifier: number;
  department_id: string | null;
}

export interface ServiceLocations {
  error: any;
  isCreating: boolean;
  isUpdating: boolean;
  results: ServiceLocation[];
}

export interface LabsAndTest {
  data_sources: DataSource[];
  details: Details | null;
  display_name: null | string;
  is_lab_results_notification_configured: boolean;
  is_popular: boolean;
  location_service_id: string;
  name: string;
  notes: null | string;
  price: null | string;
  service_id: string;
  slug: string;
}

export enum DataSource {
  Backfill = 'backfill',
  Bae = 'bae',
  ManageSettings = 'manage-settings',
  Mmtest = 'Mmtest',
  SOLV12978_Backfill = 'SOLV-12978_backfill',
  SOLV13359_PriceMigration = 'SOLV-13359_price_migration',
  Uca = 'uca',
}

export interface Details {
  location_performing?: LocationPerforming[];
  requirements: string[];
  sample_analysis?: SampleAnalysis;
  accept_cash?: boolean | null;
  accept_insurance?: boolean | null;
  time_to_result?: boolean | null | string;
  walkins_accepted?: boolean | null;
}

export enum LocationPerforming {
  AtWorksiteWhenRequested = 'At worksite when requested',
  DriveThrough = 'Drive through',
  InOffice = 'In office',
}

export interface SampleAnalysis {
  in_house?: GenericObject;
  send_out?: GenericObject;
}

export interface Availability {
  reservation: GenericObject;
  total: GenericObject;
}

export interface LocationUnformatted {
  location_id: string;
  display_name_secondary: string;
  display_name_primary: string;
  display_address: string;
  is_solv_partner: boolean;
  disable_reservations_until: string;
  time_zone: string;
  is_test_location: boolean;
  twilio_phone: string;
  is_mobile_check_in_enabled: boolean;
  mobile_check_in: boolean;
  platform_type: PlatformType;
  is_telemed: boolean;
  is_birth_sex_required: boolean;
  package_name: string;
  groups?: Location['groups'];
  is_custom_quick_reply_enabled: boolean;
  is_external_telemed: boolean;
}

export interface Brand {
  id: string;
  slug: string;
  title: string;
}

export interface ImageLinks {
  logo?: string[];
  image?: string[];
}

export interface LocationPackage {
  display_name: string;
  name: string;
  id: string;
  location_features: {
    [location_feature_column_name: string]: any;
  };
}

export interface Location extends LocationUnformatted {
  reviews: Reviews;
  acceptedInsuranceTypes: Array<Dict<boolean>>;
  isLegalNameRequired: boolean;
  mobileCheckIn: boolean;
  twilioPhone: string;
  facilities: any[];
  address: string;
  appointmentSlots: number;
  availability: Availability;
  bookingUrl: string;
  city: string;
  displayAddress: string;
  displayNamePrimary: string;
  displayNameSecondary: string;
  distanceFromCurrentLocation: number;
  groups: GroupAsSubquery[];
  id: string;
  imageLinks: ImageLinks;
  isAsapTelemedEnabled: boolean;
  isBirthSexRequired: boolean;
  isBookAheadOnWalkInSlots: boolean;
  isBookingWidgetAddressRequired: boolean;
  isCdpSrpAddressRequired: boolean;
  isKioskAddressRequired: boolean;
  isCannedTextChatOnlyEnabled: boolean;
  isConsentEnabled: boolean;
  isCustomQuickReplyEnabled: boolean;
  isEmailRequiredForBookingWidget: boolean;
  isExternalTelemed: boolean;
  isKioskCustomQuestionsEnabled: boolean;
  isMobileCheckInEnabled: boolean;
  isPaperlessHandoffEnabled: boolean;
  isPaperworkEnabled: boolean;
  isPaperworkRequired: boolean;
  isPaymentsEnabled: boolean;
  isPaymentsRequired: boolean;
  isPhotoIdBackRequired: boolean;
  isPhotoIdFrontRequired: boolean;
  isPhotoIdUploadEnabled: boolean;
  isSmoochEnabled: boolean;
  isStandaloneTelemed: boolean;
  isTelemed: boolean;
  isTestLocation: boolean;
  isVideoVisitHandoffEnabled: boolean;
  latLong: LatLong;
  loading: boolean;
  offboardedDate: string;
  officeId: string;
  packageName: string;
  packageId: string;
  package: null | undefined | LocationPackage;
  paymentAccountId: string;
  phone: string;
  platformType: PlatformType;
  serviceLocations: Array<{ service_identifier: number }>;
  services: LocationServices;
  servicesObj: LocationServicesObj;
  specialties: Specialty[];
  state: string;
  subpremise: string;
  timeZone: string;
  trackingProperties: {
    google_analytics: {
      tracker_name: string;
      id: string;
      referring_domain: string;
      force_ssl: boolean;
    };
    google_analytics4: {
      id: string;
    };
  };
  website: string;
  zipCode: string;
  name: string;
  isFormatted: boolean;
  isOpaque: boolean;
  conversations: ConversationsConversations;
  hours: LocationHours;
  hoursDefault: LocationHours;
  isSolvPartner: boolean;
  isBookable: boolean;
  isUberEnabled: boolean;
  isBookingCodesEnabled: boolean;
  disableReservationsUntil: string;
  acceptedInsurers: Array<{
    marketplace_insurer_id: string;
  }>;
  performancePricingMonthlyBudget: string;
  isPerformancePricingEnabled: boolean;
  hasBoost: boolean;
  liveDate: Date;
  bookingWidgetLanguages: Array<keyof typeof LANG_MAP> | undefined;
  contractedBookingPageEngagement: number;
  waitListCount?: number | null;
  brands: Brand[];
  $type?: 'location';
  realLocationIds: string[];
  facadeLocationIds: string[];
  facadeType: FacadeType;
  facadeLocations?: { location_id: string; facade_type: string }[];
  realLocations?: { location_id: string; facade_type: string }[];
}

export enum FacadeType {
  TelemedDispatch = 'telemed-dispatch',
}

export interface Group {
  business_authorized_contact_email: string;
  business_authorized_contact_first_name: string;
  business_authorized_contact_last_name: string;
  business_authorized_contact_phone: string;
  business_website: string;
  business_w9_address: string;
  business_w9_business_type: string;
  business_w9_city: string;
  business_w9_country: string;
  business_w9_ein: string;
  business_w9_name: string;
  business_w9_state: string;
  business_w9_stock_ticker: string;
  business_w9_subpremise: string;
  business_w9_zip_code: string;
  company_type: string;
  configuration: any[];
  domains: string[];
  id: string;
  name: string;
  twilio_brand_type: string;
}

export interface GroupAsSubquery extends Omit<Group, 'id'> {
  group_id: string;
}

// TODO would be good to move this to typescript-solv so it could be shared between mapp and manage
export interface LocationServicesObj {
  Gynecology: LabsAndTest[];
  'Labs and Tests': LabsAndTest[];
  'Other Services'?: LabsAndTest[];
  'Physical Exams': LabsAndTest[];
  Vaccinations: LabsAndTest[];
}

export interface LocationServices {
  Gynecology?: string[];
  'Labs and Tests'?: string[];
  'Other Services'?: string[];
  'Physical Exams'?: PhysicalExam[];
  Vaccinations?: Vaccination[];
  Imaging?: Imaging[];
  'General Urgent Care'?: GeneralUrgentCare[];
}

export enum OtherServices {
  BaseOfficeVisit = 'Base office visit',
}

export enum Imaging {
  XRayChest = 'X-ray (chest)',
  XRayExtremity = 'X-ray (extremity)',
}

export enum GeneralUrgentCare {
  LacerationRepair = 'Laceration repair (stitches)',
}

export enum PhysicalExam {
  AnnualPhysical = 'Annual physical',
  CampPhysicals = 'Camp physicals',
  DOTPhysicals = 'DOT physicals',
  PhysicalExam = 'Physical exam',
  SchoolPhysicals = 'School physicals',
  SportsPhysicals = 'Sports physicals',
  WellWomanExam = 'Well woman exam',
  WorkPhysicals = 'Work physicals',
}

export enum Vaccination {
  DTaPVaccine = 'DTaP vaccine',
  FluShot = 'Flu shot',
  Immunizations = 'Immunizations',
}

export type Review = {
  id: string;
  rating_solv: number;
  booking_id: string;
  consent_to_publish: boolean;
  door_to_door_time: number;
  has_consented_to_publish_patient_name: boolean;
  has_consented_to_publish_visit_type: boolean;
  is_contacted: boolean;
  is_external_telemed: null;
  is_publicly_viewable: null;
  location_display_name_primary: string;
  location_display_name_secondary: string;
  location_id: string;
  location_state: string;
  patient_email: string;
  patient_first_name: string;
  patient_last_initial: string;
  patient_name: string;
  patient_phone: string;
  provider_first_name: null;
  provider_id: null;
  provider_last_name: null;
  provider_title: null;
  rating: number;
  rating_bedside_manner: null;
  rating_date: Date;
  rating_facility_cleanliness: null;
  rating_quality_of_care: null;
  rating_staff_friendliness: null;
  rating_wait_time: null;
  reason: string;
  review: string;
  reviews_google_gmb_id: null;
  solv_ratings_id: string;
  source: string;
  visit_time: Date;
  visit_type: string;
  wait_time: number;
  created_date: string;
};

export interface Reviews {
  data: Review[];
  reviewCounter: number;
  error: string | null | Error;
  stale: boolean;
}

export interface Modal {
  modalVisible: boolean;
}

export interface PurpleGoogleAnalytics {
  force_ssl: string;
}

export interface Conversation {
  created_date: Date;
  id: string;
  links: Link[];
  most_recent_message?: SentMessage;
  most_recent_non_patient_message?: SentMessage;
  name: string;
  participants: Participant[];
  unread_count: number;
  updated_date: Date;
}

export interface Link {
  href: string;
  rel: string;
}

export interface Specialties {
  created_date: Date;
  id: string;
  display_name: string;
  display_name_plural: string;
  name: string;
  links: Link[];
  specialty_field: string;
  specialty_field_display_name: string;
  updated_date: Date;
}

export interface Brands {
  created_date: Date;
  id: string;
  links: Link[];
  slug: string;
  title: string;
  updated_date: Date | null;
}

export interface Participant {
  account_id: null | string;
  active: boolean;
  clinic_account_id: null;
  conversation_id: string;
  conversation_participant_id: string;
  created_date: Date;
  first_name: null | string;
  is_consented_patient: boolean;
  last_name: null | string;
  last_viewed_timestamp: Date | null;
  location_id: string | null;
  updated_date: Date;
}

export interface SentMessage {
  active: boolean;
  author_id: number;
  author_type: number;
  body: string;
  conversation_id: string;
  created_date: string;
  id: string;
  is_clinic_account_visible_only: boolean;
  is_pending_patient_consent: boolean;
  notification_id: string | null;
  updated_date: string;
}

export interface AccountLogin {
  account: Account;
  pubNub: PubNub;
}

export interface Account {
  active: boolean;
  address: string;
  address_street_secondary?: string;
  city: string;
  connect_access_enabled: boolean;
  created_date: string;
  daily_review_email_enabled: boolean;
  email: string;
  first_name: string;
  groups: AccountGroup[];
  id: string;
  is_address_validated: boolean;
  lab_results_access_enabled: boolean;
  last_completed_nps: string;
  last_logged_in: Date;
  last_name: string;
  links: Link[];
  locations: AccountLocation[];
  low_feedback_notifications: boolean;
  messaging_access_enabled: boolean;
  password_expiration_date: null;
  patient_boost_email_enabled: boolean;
  payments_email_enabled: boolean;
  phone: string;
  physical_location_email_notification_enabled: boolean;
  requires_password_reset: boolean;
  roles: AccountRole[];
  sms_consent: boolean;
  state: string;
  tech_check_expiration_date: null;
  telemed_email_enabled: boolean;
  telemed_sms_enabled: boolean;
  traditional_email_enabled: boolean;
  traditional_sms_enabled: boolean;
  updated_date: string;
  username: string;
  weekly_review_email_enabled: boolean;
  weekly_telemed_report_email_enabled: boolean;
  zip_code: string;
}

export interface AccountGroup {
  group_id: string;
  locations: GroupLocation[];
  name: string;
  password_ttl_days: null;
  messaging_plan: string;
}

export interface GroupLocation {
  display_name_primary: string;
  display_name_secondary: string;
  is_solv_partner: boolean;
  is_telemed: boolean;
  location_id: string;
  platform_type: PlatformType;
  state: string;
  is_performance_pricing_enabled: boolean;
}

export interface AccountLocation {
  display_name_primary: string;
  display_name_secondary: string;
  is_consent_enabled: boolean | null;
  is_kiosk_custom_questions_enabled: boolean;
  is_paperless_handoff_enabled: boolean;
  is_paperwork_enabled: boolean;
  is_payments_enabled: boolean;
  is_performance_pricing_enabled: boolean;
  is_pos_enabled: boolean;
  is_solv_partner: boolean;
  is_telemed: boolean;
  is_video_visit_handoff_enabled: boolean;
  is_address_validation_enabled: boolean;
  location_id: string;
  location_label_color: string;
  mobile_check_in: boolean;
  offboarded_date: null;
  payment_account_id: null | string;
  platform_type: PlatformType;
  service_locations: Array<{ service_identifier: number }>;
  state: string;
  package_name?: string | null;
  is_test_location: boolean;
  $type?: 'account.location';
  facade_location_ids?: string[];
  real_location_ids?: string[];
  facade_type?: FacadeType;
  facade_locations?: { location_id: string; facade_type: string }[];
  real_locations?: { location_id: string; facade_type: string }[];
}

export interface LoginToken {
  access_token: string;
  clinic_account_id: string;
  expiration_timestamp: string;
  expires_in: number;
  grant_type: string;
  refresh_token: string;
  token_type: string;
  meta?: {
    last_refresh: number;
  };
}

export type RefreshTokenResponse = Pick<
  LoginToken,
  | 'access_token'
  | 'refresh_token'
  | 'grant_type'
  | 'expires_in'
  | 'expiration_timestamp'
  | 'token_type'
>;

export interface PubNub {
  [key: string]: {
    auth_key: string;
    subscribe_key: string;
  };
}

export interface LookUp {
  lookUpType: string;
}

export interface ModalLocationIDS {
  chat: null;
  addPatient: null;
  reviews: null;
}

export enum PaperworkFieldType {
  INPUT = 'input',
  RADIO = 'radio',
  SELECT = 'select',
  CHECK_BOX = 'check_box',
  BIRTH_DATE = 'birth_date',
  RELATIONSHIP_TO_ACCOUNT = 'relationship_to_account',
  PHARMACY_SEARCH = 'pharmacy_search',
  PHONE = 'phone',
  EMAIL = 'email',
  SSN = 'ssn',
}

export interface PaperworkField {
  active: boolean;
  created_date: Date;
  dependency_field_id: number | null;
  dependency_field_name: string | null;
  field_display_name: string;
  field_name: string;
  field_type: PaperworkFieldType;
  id: string;
  is_required_field: boolean;
  is_standard_field: boolean;
  location_id: string;
  paperwork_field_id: string;
  placeholder: string;
  product_context: ProductContext;
  ranking: number;
  response_options: string[] | LabelValue[];
  type: ProductContext;
  updated_date: Date | null;
}

export enum ProductContext {
  Registration = 'registration',
}

export interface PaperworkResponses {
  responses: Array<Record<string, string>>;
}

export interface PastBookings {
  pastBookings: any[];
}

export interface Payments {
  invoices: Invoices;
  searchFilters: GenericObject;
}

export interface Invoices {
  results: any[];
}

export interface Queue {
  selectedLocationIds: string[];
  currentLocation: string;
  currentTab: QueueTab;
  error: null;
  submitting: boolean;
  submittingPatchForBookingId: boolean;
  mostRecentBooking?: Booking;
  notesModalVisible: boolean;
}

export interface Reports {
  paperlessReportsGraphData: any;
  telemedReportsGraphData: any;
  source: string;
  providerIds: string[];
  starRating: string;
  isContacted: boolean;
  data: any[];
  error: null;
  fetched: null;
  from: null;
  to: null;
  reviews: GenericObject;
  locationIds: string[];
  hasUnselectedAllLocations: boolean;
  fileFormat: string;
  hasPerformancePricingLocations: boolean;
  performancePricingLocations: object;
  locationIdsLiveMoreThan30Days: string[];
  earliestLiveDate: object;
}

export interface PatientVolumeData {
  solv: number;
  solv_new: number;
  solv_returning: number;
  clinic_website: number;
  clinic_website_new: number;
  clinic_website_returning: number;
  impressions: number;
  favorites: number;
  online_wait_time_bookings: number;
  online_wait_time_sum: number;
  walkin_wait_time_bookings: number;
  walkin_wait_time_sum: number;
  online_door_to_door_time_bookings: number;
  online_door_to_door_time_sum: number;
  walkin_door_to_door_time_bookings: number;
  walkin_door_to_door_time_sum: number;
  walkin_used_kiosk_bookings: number;
  walkin_used_kiosk_sum: number;
  online_seen_on_time_bookings: number;
  online_seen_on_time_sum: number;
  date: string;
}

export interface ReviewsModeration {
  data: any[];
  noMoreReviews: boolean;
  currentBooking: GenericObject;
}

export interface Runtime {
  facesheetActiveTab: string;
  isCancellationReasonModalVisible: boolean;
  isGettingTelemedReportsGraphData: boolean;
  isGettingPaperlessReportsGraphData: boolean;
  creatingClinicDownloadReportError: string;
  creatingClinicDownloadReportStatus: string;
  gettingSummaryData: boolean;
  gettingReviewsSummaryData: boolean;
  isGettingReviewsData: boolean;
  isGettingBookingsGraphData: boolean;
  isGettingReportsGraphData: boolean;
  currentTime: number;
  facesheetEditMode: boolean;
  initialNow: number;
  isModifyingSpecialHours: boolean;
  isFetchingAccounts: boolean;
  isFetchingLocations: boolean;
  isGettingBookingHistory: boolean;
  isGettingPastBookings: boolean;
  isResendingInvitationEmail: GenericObject;
  reservedTodayCountMayNeedRefresh: boolean;
  route: null;
  locationsImportToolWetRunId: string;
  showAddPatientModal: boolean;
  showFollowUpBookingModal: boolean;
  showAssignPatientModal: boolean;
  showFacesheetModal: boolean;
  showReviewsModal: boolean;
  showWaitlistModal: boolean;
  showEmojiPickerModal: boolean;
  showLocationsImportToolCompleteModal: boolean;
  submittingAddEditPatient: boolean;
  submittingNotes: boolean;
  isCreatingHandoffCode: boolean;
  isFetchingConsentFormSignatures: boolean;
  isFetchingInsurers: boolean;
  isFetchingPaperworkData: boolean;
  isFetchingServices: boolean;
  isFetchingSpecialties: boolean;
  isFetchingUclBrands: boolean;
  isInvalidatingCloudfrontCache: boolean;
  isMatchingLocationFoundModalVisible: boolean;
  isPhysicalLocationAssociationModalVisible: boolean;
  persistentStateLoaded: boolean;

  deletingLookerUser: boolean;
}

export interface Settings {
  currentTab: string;
  reservationHours: ReservationHours;
  operatingHours: OperatingHours;
  createEmployeeSearch: null;
  upcoming: any[];
  practiceSettings: GenericObject;
  isSubmitting: boolean;
  availabilityRecommendations: {
    availability_recommendations: {
      total: GenericObject;
    };
    loading: boolean;
  };
}

export interface CreateNewAccount {
  success: any[];
  error: any[];
  fetchError: any[];
}

export interface Enable {
  success: any[];
  error: any[];
  isEnabled: null;
}

export interface OperatingHours {
  error: null;
  operatingHours: any[];
}

export type LocationHours = {
  Friday: LocationHour[];
  Monday: LocationHour[];
  Saturday: LocationHour[];
  Sunday: LocationHour[];
  Thursday: LocationHour[];
  Tuesday: LocationHour[];
  Wednesday: LocationHour[];
};

export type LocationHour = {
  fromTime: string;
  toTime: string;
  effective_date: string;
};

export interface Rating {
  score: number;
  topic: string;
  label: string;
}

export interface ReservationHours {
  error: null;
  hours: any[];
  isLoading: boolean;
}

export interface SolvPatientBoost {
  toggleSolvPatientBoostModal: boolean;
  loadingBoostPatients: boolean;
  patientBoostBookingsRecieved: any[];
  hasRecievedNewPatientBoost: boolean;
  numberOfNewSolvBoostPatients: number;
}

export interface VisitSummary {
  bookingId: string;
  containsSensitiveMinorData: boolean;
  createdDate: string;
  downloadedByPatientDate?: string;
  id: string;
  links: Link[];
  revisionDate?: string;
  updatedDate?: string;
  viewedByPatientDate?: string;
  summaryText: string;
  revisionAuthorId: string;
}

export interface WaitList {
  results: GenericObject;
}

export interface WaitTime {
  waitTime: null;
}

export interface ServicePreference {
  encrypted: boolean;
  active: boolean;
  created_date: string;
  department_id: string;
  id: string;
  key: string;
  provider_identifier: string;
  updated_date: string;
  value: string;
}

export interface SftpExportConfig {
  data: {};
  encrypted_pkey_filename: string;
  encrypted_pkey_passphrase: string;
  frequency: string;
  hostname: string;
  id: string;
  loader_classname: string;
  password: string;
  port: number;
  remote_directory: string;
  transformer_classname: string;
  username: string;
}

export interface SftpExportConfigState {
  isCreating: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  results: SftpExportConfig[];
}

export interface UserProfile {
  photo_upload_date: string;
  account_id: string;
  address_street: string;
  address_street_secondary: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  is_address_validated: boolean;
  first_name: string;
  middle_name: string;
  last_name: string;
  phone: string;
  phone_primary: string;
  email: string;
  birth_sex: string;
  birth_date: string;
  race: string;
  ethnicity: string;
  pharmacy: string;
  gender: string;
  id: string;
  photo_id_front_id?: string;
  photo_id_back_id?: string;
}

export enum BrandRegistrationStatus {
  APPROVED = 'APPROVED',
  FAILED = 'FAILED',
  IN_REVIEW = 'IN_REVIEW',
  PENDING = 'PENDING',
}

export enum BrandType {
  STANDARD = 'standard',
  STARTER = 'starter',
}

export enum CustomerProfileStatus {
  IN_REVIEW = 'in-review',
  PENDING_REVIEW = 'pending-review',
  TWILIO_REJECTED = 'twilio-rejected',
}

export enum TrustProductStatus {
  IN_REVIEW = 'in-review',
  PENDING_REVIEW = 'pending-review',
  TWILIO_REJECTED = 'twilio-rejected',
}

export enum SolvRegistrationStatus {
  COMPLETED = 'completed',
  MISSING_DATA = 'missing data',
  REJECTED = 'rejected',
}

export interface Twilio10DLCRegistration {
  solvRegistrationStatus: SolvRegistrationStatus;
  brandRegistrationFailureReason: any;
  brandRegistrationFeedback: any;
  brandRegistrationSid: string;
  brandRegistrationStatus: BrandRegistrationStatus;
  brandType: BrandType;
  customerProfileSid: string;
  customerProfileStatus: CustomerProfileStatus;
  trustProductStatus: TrustProductStatus;
  campaignRegistrationStatus: BrandRegistrationStatus;
}

export interface MessageTemplate {
  id: string;
  authorId: string | null;
  locationId: string | null;
  groupId: string | null;
  translations: { [key: string]: string };
  variant: Variant | null;
  notificationType: NotificationType | null;
  templateContent: string;
  displayName: string;
  ranking: number | null;
  context: 'quick_replies' | 'bookings_comms';
  metadata: any;
  active: boolean;
}
